// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"c176fdf26f2995e17876b7723cbea1d37b94445e"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import * as Spotlight from '@spotlightjs/spotlight'

Sentry.init({
	dsn: 'https://8a9df785b1dd945f7249e534abeb5788@o4507692016533504.ingest.us.sentry.io/4508565201551360',

	// Add optional integrations for additional features
	integrations: [
		Sentry.replayIntegration(),
	],

	// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
	tracesSampleRate: 1,

	// Define how likely Replay events are sampled.
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// Define how likely Replay events are sampled when an error occurs.
	replaysOnErrorSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,
})

if (process.env.NODE_ENV === 'development') {
	void Spotlight.init({
		anchor: 'centerRight',
	})
}
